import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import api from './api'
import appState from './app-state'
import appConfig from './app-config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    appConfig,
    appState,
  },
  strict: process.env.DEV,
})
