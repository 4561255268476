import { datadogLogs } from '@datadog/browser-logs'

export const init = () => {
  if (process.env.VUE_APP_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.VUE_APP_DATADOG_SITE,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: 'account',
      silentMultipleInit: true,
    })
  }
}

export function info(message) {
  datadogLogs.logger.info(message)
}

export function error(message, err) {
  if ((typeof err === 'object' || typeof err === 'string') && err !== null) {
    return datadogLogs.logger.error(message, { error: err })
  }

  datadogLogs.logger.error(message, { message: `Non-supported error type: ${typeof err}` })
}
