/* eslint-disable no-return-assign */
/* eslint-disable no-empty-pattern */

// State management for the app (not API related data).
export default {
  namespaced: true,
  state: {
    licencesSelected: 0,
  },
  getters: {
  },
  mutations: {
    UPDATE_LICENCES_SELECTED(state, value) {
      state.licencesSelected = value
    },
  },
  actions: {
    updateSelectedLicences({ commit }, value) {
      commit('UPDATE_LICENCES_SELECTED', value)
    },
  },
}
