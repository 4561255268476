import Vue from 'vue'
import VueRouter from 'vue-router'
import { isTokenValid } from '@/utils'
import { trackEvent } from '@core/utils/tracking'

Vue.use(VueRouter)

const reactPocEnabled = process.env.VUE_APP_REACT_POC_ENABLED === 'true'
const BILLING_V2_ENABLED = process.env.VUE_APP_BILLING_V2_ENABLED === 'true'
const BILLING_PAGE = BILLING_V2_ENABLED
  ? import('@/views/billing-v2/BillingV2.vue')
  : import('@/views/billing/Billing.vue')

const CHANGE_PLAN_PAGE = BILLING_V2_ENABLED
  ? import('@/views/billing-v2/ChangePlanV2.vue')
  : import('@/views/billing/ChangePlan.vue')

const REWARDS_PROGRAM_ENABLED = process.env.VUE_APP_REWARDS_PROGRAM_ENABLED === 'true'

const routes = [
  // Account
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Account.vue'),
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/team/Team.vue'),
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => BILLING_PAGE,
  },
  {
    path: '/v1/billing',
    name: 'billingV1',
    component: () => import('@/views/billing/Billing.vue'),
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/analytics/Analytics.vue'),
  },
  {
    path: '/billing/licences',
    redirect: '/billing',
  },
  {
    path: '/change-plan',
    name: 'change-plan',
    component: () => CHANGE_PLAN_PAGE,
  },
  {
    path: '/v1/change-plan',
    name: 'change-planV1',
    component: () => import('@/views/billing/ChangePlan.vue'),
  },

  // Features
  {
    path: '/features/default-fields',
    name: 'default-fields',
    component: () => import('@/views/features/DefaultFields.vue'),
  },
  {
    path: '/features/fields-mapping',
    name: 'fields-mapping',
    component: () => import('@/views/features/FieldsMapping.vue'),
  },
  {
    path: '/features/tools-apps',
    name: 'tools-apps',
    component: () => import('@/views/features/ToolsApps.vue'),
  },
  {
    path: '/features/api',
    name: 'public-api',
    component: () => import('@/views/features/PublicAPI.vue'),
  },
  {
    path: '/features/oauth-cb/:tool',
    name: 'oauth-cb',
    component: () => import('./views/features/OAuthCB.vue'),
    meta: { public: true },
  },

  // GSheets
  {
    path: '/connect-gsheets',
    name: 'connect-gsheets',
    component: () => import('@/views/gsheets/Connect.vue'),
    meta: { layout: 'full', public: true },
  },
  {
    path: '/callback-gsheets',
    name: 'callback-gsheets',
    component: () => import('@/views/gsheets/Callback.vue'),
    meta: { layout: 'full', public: true },
  },

  // Enrichment hub
  {
    path: '/enrichment-hub/connect',
    name: 'enrichment-hub',
    component: () => import('@/views/enrichment-hub/Connect.vue'),
    meta: { layout: 'full', public: true },
  },
  {
    path: '/enrichment-hub/cb',
    name: 'enrichment-hub/cb',
    component: () => import('@/views/enrichment-hub/Callback.vue'),
    meta: { layout: 'full', public: true },
  },
  {
    path: '/enrichment-hub/home',
    name: 'enrichment-hub/home',
    component: () => import('@/views/enrichment-hub/Home.vue'),
    meta: { layout: 'full', eh: true },
  },

  // Support
  {
    path: '/support/onboarding-hubspot',
    name: 'onboarding-hubspot',
    component: () => import('@/views/support/OnboardingHubspot.vue'),
    meta: {
      layout: 'article',
      public: true,
    },
  },
  {
    path: '/support/onboarding-pipedrive',
    name: 'onboarding-pipedrive',
    component: () => import('@/views/support/OnboardingPipedrive.vue'),
    meta: {
      layout: 'article',
      public: true,
    },
  },
  {
    path: '/support/onboarding-copper',
    name: 'onboarding-copper',
    component: () => import('@/views/support/OnboardingCopper.vue'),
    meta: {
      layout: 'article',
      public: true,
    },
  },
  {
    path: '/support/onboarding-salesforce',
    name: 'onboarding-salesforce',
    component: () => import('@/views/support/OnboardingSalesforce.vue'),
    meta: {
      layout: 'article',
      public: true,
    },
  },

  // Other
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/connect',
    name: 'connect',
    component: () => import('@/views/Connect.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/confirm-extension/code/:code',
    name: 'confirm-extension',
    component: () => import('@/views/ConfirmExtension.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/uninstall',
    name: 'uninstall',
    component: () => import('@/views/others/Uninstall.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/unsubscribe/:userId/:emailId',
    name: 'unsubscribe',
    component: () => import('@/views/others/Unsubscribe.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: () => import('@/views/others/Cancel.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/settings/account',
    redirect: '/settings',
  },
  {
    path: '/settings/team',
    redirect: '/team',
  },
  {
    path: '/embed/payment',
    name: 'embed',
    component: () => import('@/views/Embed/BillingPayment.vue'),
    meta: { layout: 'embed', isEmbedded: true },
  },
  {
    path: '*',
    redirect: '/team',
  },
]

if (reactPocEnabled) {
  routes.push({
    path: '/react-poc',
    name: 'react-poc',
    component: () => import('@/views/react-poc/ReactPoc.vue'),
  })
}

if (REWARDS_PROGRAM_ENABLED) {
  routes.push({
    path: '/rewards-program',
    name: 'rewards-program',
    component: () => import('@/views/rewards-program/RewardsProgram.vue'),
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { public: isPublic, isEmbedded, eh: isEH } = to.meta

  const isValidAuth = isPublic || isTokenValid()
  if (isEmbedded || isValidAuth) {
    if ((!isPublic && !isEH) || to.path === '/uninstall') {
      await trackEvent({ type: 'dashboard-page_viewed', props: { page: to.path } })
    }
    next()
    return
  }

  localStorage.removeItem('key')
  const path = isEH ? '/enrichment-hub/connect' : '/login'
  const redirectPath = window.btoa(to.fullPath)
  next({
    path,
    query: {
      redirect: redirectPath,
    },
  })
})

export default router
